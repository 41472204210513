<template>
  <create-item-component
      endpoint="ideas"
      type="idea"/>
</template>
<script>

export default {
  name: "Ideas"
}
</script>